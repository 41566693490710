
import { ref, defineComponent } from "vue"
import { useRoute, useRouter } from "vue-router"
import OrganizationService from "@/services/OrganizationService"
import { useConfirm } from "primevue/useconfirm"
import OrganizationContactInfo from "./OrganizationContactInfo.vue"
import OrganizationAdressInfo from "./OrganizationAdressInfo.vue"

export default defineComponent({
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props, { emit }) {
    const form = ref()
    const confirm = useConfirm()
    const router = useRouter()
    const organizationService = OrganizationService.getInstance()
    function onSaveChanges(organizationData: any) {
      organizationService.updateOrganization(props.organization.id, organizationData).then(() => {
        // Do nothing
        emit("value-changed")
        form.value.onDone()
      })
    }
    function onDelete() {
      organizationService.deleteOrganization(props.organization.id).then(() => {
        router.push({ name: "organizations" })
      })
    }
    function onConfirmDelete(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Delete oprganization",
        message: "Are you sure you want to remove this organization?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: onDelete,
      })
    }
    return {
      form,
      onSaveChanges,
      onConfirmDelete,
    }
  },
  components: { OrganizationContactInfo, OrganizationAdressInfo },
})
