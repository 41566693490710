
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { ComputedRef, computed, reactive, ref } from "vue"
import InputSwitch from "primevue/inputswitch"
import OrganizationService from "@/services/OrganizationService"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  components: { InputSwitch, PrimaryButton },
  props: {
    address: {
      type: Object,
    },
  },
  emits: ["submit", "delete"],
  setup(props: any, { emit }: any) {
    const service = OrganizationService.getInstance()
    const allAddressTypes = reactive([
      {
        label: "Billing",
        category: "BILLING",
        standard: false,
        selected: props.address
          ? !!props.address.addressTypes.find((addressType: any) => {
              return addressType.category === "BILLING" && !addressType.standard
            })
          : false,
      },
      {
        label: "Billing default",
        category: "BILLING",
        standard: true,
        selected: props.address
          ? !!props.address.addressTypes.find(
              (addressType: any) => addressType.category === "BILLING" && addressType.standard
            )
          : false,
      },
      {
        label: "Delivery",
        category: "DELIVERY",
        standard: false,
        selected: props.address
          ? !!props.address.addressTypes.find(
              (addressType: any) => addressType.category === "DELIVERY" && !addressType.standard
            )
          : false,
      },
      {
        label: "Delivery default",
        category: "DELIVERY",
        standard: true,
        selected: props.address
          ? !!props.address.addressTypes.find(
              (addressType: any) => addressType.category === "DELIVERY" && addressType.standard
            )
          : false,
      },
      {
        label: "Reference",
        category: "REFERENCE",
        standard: false,
        selected: props.address
          ? !!props.address.addressTypes.find(
              (addressType: any) => addressType.category === "REFERENCE" && !addressType.standard
            )
          : false,
      },
    ])

    const selectedAddressTypes: ComputedRef = computed(() =>
      allAddressTypes.filter((addressType: any) => addressType.selected)
    )

    const form = reactive({
      name1: props.address ? props.address.name1 : "",
      name2: props.address ? props.address.name2 : "",
      alias: props.address ? props.address.alias : "",
      line1: props.address ? props.address.line1 : "",
      line2: props.address ? props.address.line2 : "",
      line3: props.address ? props.address.line3 : "",
      zip: props.address ? props.address.zip : "",
      city: props.address ? props.address.city : "",
      country: props.address ? props.address.country : "",
      addressTypes: selectedAddressTypes,
    })
    const rules = {
      name1: { name1Required: helpers.withMessage("The name1 is required", required) },
      name2: {},
      alias: {},
      line1: {},
      line2: {},
      line3: {},
      zip: {},
      city: {},
      country: {},
      addressTypes: {
        addressTypesRequired: helpers.withMessage("At least 1 address type is required", required),
      },
    }
    const v$: any = useVuelidate(rules, form)

    const addressTypeOptions = ref([
      { name: "Billing", value: "BILLING" },
      { name: "Delivery", value: "DELIVERY" },
      { name: "Reference", value: "REFERENCE" },
    ])

    function onSubmit() {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        const addressData = {
          name1: form.name1,
          name2: form.name2,
          alias: form.alias,
          line1: form.line1,
          line2: form.line2,
          line3: form.line3,
          zip: form.zip,
          city: form.city,
          country: form.country,
          addressTypes: form.addressTypes,
        }
        if (props.address) {
          emit("submit", addressData, props.address.id)
        } else {
          emit("submit", addressData, null)
        }
      }
    }

    function onAddressTypeClick(addressType: any) {
      addressType.selected = !addressType.selected
      if (addressType.category === "BILLING" && addressType.standard) {
        allAddressTypes[0].selected = false
        if (props.address) {
          deleteAddressTypeFromAddress({ category: "BILLING", standard: false })
        }
      }
      if (addressType.category === "BILLING" && !addressType.standard) {
        allAddressTypes[1].selected = false
        if (props.address) {
          deleteAddressTypeFromAddress({ category: "BILLING", standard: true })
        }
      }
      if (addressType.category === "DELIVERY" && addressType.standard) {
        allAddressTypes[2].selected = false
        if (props.address) {
          deleteAddressTypeFromAddress({ category: "DELIVERY", standard: false })
        }
      }
      if (addressType.category === "DELIVERY" && !addressType.standard) {
        allAddressTypes[3].selected = false
        if (props.address) {
          deleteAddressTypeFromAddress({ category: "DELIVERY", standard: true })
        }
      }
      if (props.address) {
        if (addressType.selected) {
          addAddressTypeToAddress(addressType)
        } else {
          deleteAddressTypeFromAddress(addressType)
        }
      }
    }

    function addAddressTypeToAddress(addressType: any) {
      return service.addAddressTypeToAddress(
        props.address.id,
        addressType.category,
        addressType.standard
      )
    }

    function deleteAddressTypeFromAddress(addressType: any) {
      return service.deleteAddressTypeFromAddress(
        props.address.id,
        addressType.category,
        addressType.standard
      )
    }

    function deleteAddress() {
      emit("delete")
    }

    return {
      onSubmit,
      v$,
      addressTypeOptions,
      onAddressTypeClick,
      allAddressTypes,
      deleteAddress,
    }
  },
}
