let baseUrl = "https://www.google.com/maps/search/?api=1&query="
let comma = "%2C"
let space = "+"
let pipe = "%7C"

export function convertToGoogleMapsLink(
  line1: string,
  line2: string,
  line3: string,
  zip: string,
  city: string,
  country: string
) {
  let result = baseUrl
  if (line1) {
    result += line1.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe) + comma + space
  }
  if (line2) {
    result += line2.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe) + comma + space
  }
  if (line3) {
    result += line3.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe) + comma + space
  }
  if (zip) {
    result += zip.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe) + comma + space
  }
  if (city) {
    result += city.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe) + comma + space
  }
  if (country) {
    result += country.replace(/ /g, space).replace(/,/g, comma).replace("|", pipe)
  }
  return result
}
