
import { convertToGoogleMapsLink } from "@/helpers/AddressHelper"
export default {
  name: "AddressTag",
  props: {
    address: Object,
  },
  emits: ["edit-address-info"],
  setup(props: any, { emit }: any) {
    function onAddressClick() {
      emit("edit-address-info", props.address)
    }

    function showInMaps(event: any) {
      let addressUrl = convertToGoogleMapsLink(
        props.address.line1,
        props.address.line2,
        props.address.line3,
        props.address.zip,
        props.address.city,
        props.address.country
      )
      window.open(addressUrl, "_blank")
      event.stopPropagation()
    }

    function goToMaps(event: any) {
      if (props.address.line1 && props.address.zip && props.address.city && props.address.country) {
        const cityString = [props.address.zip, props.address.city]
          .filter((x) => x)
          .map((val) => "+" + val)
          .join("")
        const searchUrl = `https://www.google.com/maps/place/${props.address.line1.replace(
          " ",
          "+"
        )},${cityString},+${props.address.country}`
        window.open(searchUrl, "_blank", "noopener, noreferrer")
        event.stopPropagation()
      }
    }

    return {
      onAddressClick,
      showInMaps,
      goToMaps,
    }
  },
}
