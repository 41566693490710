
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import DataCollection from "../data/DataCollection.vue"
import OrganizationService from "@/services/OrganizationService"
import ResultSet from "@/models/ResultSet"
import OrganizationAdressForm from "./OrganizationAdressForm.vue"
import AddressTag from "./contacts/AddressTag.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  emit: [],
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props: any) {
    const sort = ref("alias,asc")
    const service = OrganizationService.getInstance()
    const addressInfoDialog = ref(false)
    const deleteAddressDialog = ref(false)
    const addresses = reactive([])

    const selectedAddress = ref()

    function reload() {
      addresses.length = 0
      selectedAddress.value = null
      addressInfoDialog.value = false
      getAddresses(sort.value)
    }

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    getAddresses(sort.value)

    function getAddresses(sort: any) {
      if (props.organization != null) {
        service.getAddresses(props.organization.id, 0, sort).then((result: ResultSet<any>) => {
          let filteredDeleted = []
          for (let row of result.rows) {
            if (!row.deleted) {
              filteredDeleted.push(row)
            }
          }
          result.rows.length = 0
          result.rows = filteredDeleted
          result.rows.forEach(function (element) {
            addresses.push(element)
          })
        })
      }
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    function addAddressOrEdit(addressData: any, addressId: string) {
      if (addressId) {
        service.editAddress(addressId, addressData).then((res) => {
          reload()
        })
      } else {
        service.addAddress(props.organization.id, addressData).then((res) => {
          reload()
        })
      }
    }

    function deleteAddress() {
      if (!deleteAddressDialog.value) {
        deleteAddressDialog.value = true
        return
      }

      if (selectedAddress.value) {
        service.deleteAddresses(selectedAddress.value.id).then((res) => {
          reload()
        })
      }
      deleteAddressDialog.value = false
    }

    function newAddress() {
      selectedAddress.value = null
      addressInfoDialog.value = true
    }

    function editAddress(address: any) {
      selectedAddress.value = address
      addressInfoDialog.value = true
    }

    const sortOptions = ref([
      { label: "city ↓", value: "city,asc" },
      { label: "city ↑", value: "city,desc" },
    ])

    return {
      sortOptions,
      getAddresses,
      newAddress,
      addressInfoDialog,
      deleteAddressDialog,
      selectedAddress,
      editAddress,
      addAddressOrEdit,
      deleteAddress,
      addresses,
      sortColumn,
    }
  },
  components: { DataCollection, OrganizationAdressForm, AddressTag, PrimaryButton },
}
